<template>
  <div>
    <h4 class="font-weight-bold pb-1 indigo--text">Gestión</h4>
    <h5 class="font-weight-light pb-3">Gestión de Proyectos</h5>

    <v-card class="elevation-15" id="cardborde">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="blue accent-4"
      ></v-progress-linear>
      <v-card-title class="font-weight-light">
        Lista de Proyectos
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          color="primary"
          label="Buscar"
          hide-details
          class="m-2"
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-tabs right>
        <v-tab @click="bEstado = 'Proyectos'">Proyectos</v-tab>
        <v-tab @click="bEstado = 'ITO'">ITO</v-tab>
        <v-tab @click="bEstado = 'Todos'">Todos</v-tab>
      </v-tabs>
      <v-data-table
        class="mt-3"
        :headers="headers"
        :items="filteredItems"
        :no-results-text="noresult"
        no-data-text="Sin resultados que mostrar"
        :search="search"
        :custom-filter="filterOnlyCapsText"
        :sort-by="['nro_proyecto']"
        :sort-desc="[true]"
        :custom-sort="customSort"
        :footer-props="{
          'items-per-page-text': 'Resultados por página',
          'items-per-page-all-text': 'Todos',
        }"
      >
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:item.url="{ item }">
          <v-icon small class="mr-2" @click="verURL(item.url)"> mdi-web </v-icon>
        </template>
        <template v-slot:item.accion="{ item }">
          <v-icon
            small
            class="mr-2 blue--text text--darken-5"
            @click="editItem(item._id)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            v-if="usuarioDB.data.role == 'ADMIN'"
            class="mr-2 red--text text--darken-4"
            @click="deleteItem(item._id)"
          >
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:item.status="{ item }">
          <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
          <v-chip class="ma-2" dark small :color="getEstados(item.status)">
            {{ item.status }}
          </v-chip>
        </template>

        <template v-slot:item.responsable="{ item }">
          <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
          <v-chip
            class="ma-2"
            dark
            small
            :color="item.color"
            data-toggle="tooltip"
            data-placement="bottom"
            :title="item.responsableCompleto"
          >
            {{ item.responsable }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar v-model="snackbar" color="green" dark timeout="1500" align="center">
      URL copiada exitosamente!
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

//Definimos la clase de los elementos

export default {
  data() {
    return {
      loading: true,
      noresult: "No existen resultados",
      search: "",
      dialogVerURL: false,
      urlSitio: "",
      snackbar: false,
      headers: [
        {
          text: "Nº Pyto",
          align: "start",
          sortable: true,
          value: "nro_proyecto",
          width: "15%",
        },
        {
          text: "Nº Ppto",
          align: "start",
          sortable: true,
          value: "nro_presupuesto",
          width: "15%",
        },
        {
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "tipo",
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "nombre",
          width: "20%",
        },
        {
          text: "Mandante",
          value: "mandante",
          sortable: true,
          width: "20%",
        },
        {
          text: "Resp.",
          value: "responsable",
          sortable: true,
          width: "1%",
        },

        {
          text: "Valor",
          value: "valor",
          sortable: true,
        },

        {
          text: "Estado",
          value: "status",
        },

        {
          text: "Accion",
          value: "accion",
          sortable: "false",
        },
      ],
      desserts: [],
      bEstado: "Proyectos",
      tipo: [
        {
          tipo: "1",
          text: "Proyectos",
        },
        {
          tipo: "2",
          text: "ITO",
        },
      ],
      tipos_moneda: [
        {
          tipo: 1,
          text: "U.F",
        },
        {
          tipo: 2,
          text: "Pesos Chilenos",
        },
      ],
      estados: [
        {
          tipo: 1,
          text: "Pendiente",
        },
        {
          tipo: 2,
          text: "Aprobado",
        },
        {
          tipo: 3,
          text: "Rechazado",
        },
      ],
      estados_proyecto: [
        {
          tipo: 1,
          text: "Activo",
        },
        {
          tipo: 2,
          text: "Congelado",
        },
        {
          tipo: 3,
          text: "Finalizado",
        },
      ],
    };
  },

  computed: {
    ...mapState(["token", "notificationSystem", "usuarioDB"]),
    filteredItems() {
      if (this.bEstado == "Todos") {
        return this.desserts;
      } else {
        return this.desserts.filter((i) => {
          return i.tipo === this.bEstado;
        });
      }

      //return this.desserts;
    },
  },
  methods: {
    editItem(id) {
      this.$router.push({
        name: "proyecto",
        params: {
          id: id,
        },
      });
    },
    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      let filtro = this.$options.filters;

      axios.get("/proyectos/proyecto/", config).then((response) => {
        //this.desserts = response.data;
        //console.log(response.data);
        var dataResponse = response.data;
        var responsefinal = [];
        for (let dato in dataResponse) {
          //console.log(dataResponse[dato].presupuestos);
          for (let pres of dataResponse[dato].presupuestos) {
            //console.log(pres);
            pres.status = filtro.filtro_status(pres.status, this.estados);
            pres.fecha_envio = filtro.fecha_normal(pres.fecha_envio);
            pres.tipo = filtro.filtro_tipos(pres.tipo, this.tipo);
            pres.valor = filtro.filtro_valor(
              filtro.formato_numero2(pres.valor),
              pres.tipo_moneda
            );
            let nombreMandante = "";
            if (pres.mandante.nombre) {
              nombreMandante = pres.mandante.nombre;
            }

            let nombreResponsable = "";
            let colorResponsable = "";
            if (dataResponse[dato].encargado) {
              nombreResponsable = dataResponse[dato].encargado.nombre;
              colorResponsable = dataResponse[dato].encargado.color;
            }

            responsefinal.push({
              _id: dataResponse[dato]._id,
              nombre: dataResponse[dato].nombre,
              nro_proyecto: dataResponse[dato].nro_proyecto,
              nro_presupuesto: pres.nro_presupuesto,
              tipo: pres.tipo,
              mandante: nombreMandante,
              responsable: filtro.sigla(nombreResponsable),
              responsableCompleto: nombreResponsable,
              color: colorResponsable,
              valor: filtro.formato_numero2(dataResponse[dato].monto_documento),
              status: filtro.filtro_status(
                dataResponse[dato].status,
                this.estados_proyecto
              ),
            });
          }
        }
        this.desserts = responsefinal;
        //console.log(this.desserts);
        this.loading = false;
      });
    },
    async deleteItem(item) {
      this.loading = true;
      let config = {
        headers: {
          token: this.token,
        },
      };

      await axios
        .delete("/proyectos/proyecto/" + item, config)
        .then((response) => {
          this.getData();
          this.$toast.success(
            "Eliminado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    getEstados(estado) {
      if (estado == "Congelado") return "light-blue";
      else if (estado == "Activo") return "light-green darken-2";
      else if (estado == "Finalizado") return "cyan";
    },

    getEstadosTexto(estado) {
      if (estado == true) return "check";
      else if (estado == false) return "close";
      else return "N/A";
    },

    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    customSort(items, index, isDesc) {
      let comparador = (fecha1, fecha2, desc) => {
        if (!fecha1) {
          return 1;
        }
        if (!fecha2) {
          return -1;
        }

        let fecha1Splitted = fecha1.split("/");
        let fecha2Splitted = fecha2.split("/");
        let fecha11 =
          fecha1Splitted[2] + "-" + fecha1Splitted[1] + "-" + fecha1Splitted[0];
        let fecha22 =
          fecha2Splitted[2] + "-" + fecha2Splitted[1] + "-" + fecha2Splitted[0];
        let date1 = new Date(fecha11);
        let date2 = new Date(fecha22);
        return date1 < date2 ? 1 : -1;
      };

      let nros_proyectos = (a, b, isDesc) => {
        let va = a.replace(/[^0-9]+/g, "");
        let vb = b.replace(/[^0-9]+/g, "");
        if (!isDesc) {
          if (va < vb) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (vb < va) {
            return 1;
          } else {
            return -1;
          }
        }
      };

      items.sort((a, b) => {
        if (index[0] === "fecha_estimada_realizacion") {
          if (!isDesc[0]) {
            return comparador(
              a.fecha_estimada_realizacion,
              b.fecha_estimada_realizacion,
              2
            );
          } else {
            return comparador(
              b.fecha_estimada_realizacion,
              a.fecha_estimada_realizacion,
              1
            );
          }
        } else if (index[0] === "nro_proyecto") {
          return nros_proyectos(a.nro_proyecto, b.nro_proyecto, isDesc[0]);
        } else if (index[0] === "nro_presupuesto") {
          return nros_proyectos(a.nro_presupuesto, b.nro_presupuesto, isDesc[0]);
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
  },
  created() {
    this.getData();
  },
  filters: {
    filtro_tipos: function (valor, tipos) {
      if (tipos.filter((res) => res.tipo == valor).length > 0) {
        return tipos.filter((res) => res.tipo == valor)[0].text;
      } else {
        return "";
      }
    },
    filtro_status: function (valor, tipos) {
      if (tipos.filter((res) => res.tipo == valor).length > 0) {
        return tipos.filter((res) => res.tipo == valor)[0].text;
      } else {
        return "";
      }
    },
    filtro_valor: function (valor, tipo_moneda) {
      if (tipo_moneda == "1") {
        return `U.F ${valor}`;
      } else {
        return `$ ${valor}`;
      }
    },
    fecha_normal: function (valor) {
      return moment(valor, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    formato_numero(valor) {
      return new Intl.NumberFormat("de-DE").format(Math.round(valor).toFixed(0));
    },
    formato_numero2(valor) {
      console.log(valor);
      return new Intl.NumberFormat("de-DE").format(valor);
    },
    sigla(valor) {
      let splitted = valor.split(" ");
      let ret = "";
      for (let i = 0; i < splitted.length; i++) {
        ret = ret + splitted[i][0];
      }

      return ret.toLocaleUpperCase();
    },
  },
};
</script>
<style>
th span {
  color: "#FF0000" !important;
  border: 0px solid #7181b8;
  border-radius: 44px;
  padding: 5px;
  background: #e8eaf6;
}
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
</style>
